import {mapState,mapMutations} from 'vuex'
export default {
    data() {
      return {
        
      };
    },
    
    created() {
      
    },
    computed:{
        ...mapState(['language']),
    },
    watch: {
        language: {
            immediate: true,
            deep:true,
            handler(val) {
                console.log(val)
                this.$forceUpdate()
            }
        }
    },
    methods: {
        ...mapMutations(['SET_LANG']),
        translateText(text){
            if(!text || text.length == 0){
                return ''
            }
            let lang = sessionStorage.getItem('language') || 'zh'

            var array = text.split('/')
            if(lang == 'zh' && array.length > 0){
                return array[0]
            }
            if(lang == 'es' && array.length > 1){
                return array[1]
            }
            if(lang == 'en' && array.length > 2){
                return array[2]
            }
            return array[0]
        }
    }
  };