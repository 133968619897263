import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
// 创建一个store
export default new Vuex.Store({
    // 设置全局访问的state对象
    state: {
        language: sessionStorage.getItem('language') || 'zh',
    },
    // 修改状态
    mutations: {
        SET_LANG(state,lang) {
            state.language = lang
            sessionStorage.setItem('language',lang)
        }
    }
})
