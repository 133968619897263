import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "@/style/common.scss"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import { getShop } from "@/api/user.js"; //引入文件
import mixin from '@/common/mixins.js'
import store from '@/store/index.js'
Vue.use(ElementUI);
Vue.mixin(mixin)
Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
  getShop().then(res=>{
    document.title=res.data.data.shopName;
  })

next()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
