<template>
  <div class="footer">
    <div class="container">
      <div class="footer_nav_container">
        <div class="item">
          <div class="title">INFORMACIÓN</div>
          <div
            class="article"
            @click="heleDeatils(item)"
            v-for="(item, index) in help"
            :key="index"
          >
            {{ translateText(item.title) }}
          </div>
        </div>
        <div class="item">
          <div class="title">SOBRE NOSOTROS</div>
          <div class="article">{{ translateText(ysname) }}</div>
        </div>
        <div class="item">
          <div class="title">DATOS DE CONTACTO</div>
          <div class="article">{{ translateText(info.shopName) }}</div>
          <div class="article">{{ translateText(info.address) }}</div>
          <div class="article">{{ info.tel }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { register, getShop, getMenuList } from "@/api/user.js"; //引入文件
export default {
  data() {
    return {
      help: [],
      info: {},
      ysname: "",
    };
  },
  methods: {
    getInfo() {
      register({
        status: 2,
      }).then((res) => {
        this.help = res.data.data.list;
        // console.log(this.help)
      });
      getShop().then((res) => {
        this.info = res.data.data;
      });
    },
    getMenu() {
      getMenuList({
        status: 2,
      }).then((res) => {
        this.ysname = res.data.data[0].spanish_name;
        // console.log(res)
      });
    },
    heleDeatils(data) {
      console.log(data);
      this.$router.push({
        path: "/details",
        query: {
          data: JSON.stringify(data.content),
        },
      });
    },
  },
  created() {
    this.getInfo();
    this.getMenu();
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background: rgb(46, 46, 46);
  color: #ffffff;
}
.footer_nav_container {
  display: flex;
  padding: 50px 0 50px 0;
  //border-bottom: 1px solid rgba(83,82,82,.2)
  .item {
    width: 33%;
    justify-content: space-between;
  }
  @media (max-width: 769px) {
    flex-wrap: wrap;
    .item {
      width: 100%;
      text-align: center;
    }
  }

  &.space_item {
    padding: 0 21px;
  }
  .title {
    margin-bottom: 25px;
    font-size: 20px;
    color: #ffffff;
  }

  .article {
    font-size: 16px;
    color: #ffffff;
    line-height: 14px;
    margin-bottom: 15px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
</style>