import request from './request.js'
import qs from 'qs';

export function getCarousel(params) {
    let lang = sessionStorage.getItem('language') || 'zh'
    if(params){
        params.lang = lang
    }else{
        params = {'lang':lang}
    }
    return request({
        url: '/api/ad/getCarousel',
        method: 'post',
        data:qs.stringify(params)
    });
}
export function getMenuList(params) {
    let lang = sessionStorage.getItem('language') || 'zh'
    if(params){
        params.lang = lang
    }else{
        params = {'lang':lang}
    }
    return request({
        url: '/api/article_menu/list',
        method: 'post',
        data:qs.stringify(params)
    });
}
export function getMenuContent(params) {
    let lang = sessionStorage.getItem('language') || 'zh'
    if(params){
        params.lang = lang
    }else{
        params = {'lang':lang}
    }
    return request({
        url: '/api/article/list',
        method: 'post',
        data:qs.stringify(params)
    });
}
export function getShop(params) {
    let lang = sessionStorage.getItem('language') || 'zh'
    if(params){
        params.lang = lang
    }else{
        params = {'lang':lang}
    }
    return request({
        url: '/api/shop/get',
        method: 'post',
        data:qs.stringify(params)
    });
}
export function register(params) {
    let lang = sessionStorage.getItem('language') || 'zh'
    if(params){
        params.lang = lang
    }else{
        params = {'lang':lang}
    }
    return request({
        url: '/api/article/getHelpCenter',
        method: 'post',
        data:qs.stringify(params)
    });
}