<template>
  <div class="header">
    <div class="container">
      <div class="header_container">
        <div class="logo_content">
          <img :src="logo" class="logo" />
        </div>
        <div class="nav_bottom" v-if="showMenu">
          <div class="top_btn" :class="active == item.name ? 'active' : ''" v-for="(item, index) in menu" :key="index"
            @click="menuClick(item.name, item.articleMenuId)">
            {{ translateText(item.spanishName) }}
          </div>
          <el-dropdown class="top_btn" trigger="click" @command="selectLanguage">
            <span class="el-dropdown-link">
              {{ getLanguageName() }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item.code" v-for="(item, index) in languages"
                :key="index">{{ item.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="nav_bottom" v-if="showSelct">
          <el-dropdown @command="handleCommand" :hide-on-click="true">
            <span class="el-dropdown-link">
              <i class="el-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item.articleMenuId" v-for="(item, index) in menu" :key="index">{{
                translateText(item.spanishName)
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown class="top_btn" trigger="click" @command="selectLanguage">
            <span class="el-dropdown-link">
              {{ getLanguageName() }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item.code" v-for="(item, index) in languages"
                :key="index">{{ item.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMenuList, getShop } from "@/api/user.js"; //引入文件
export default {
  data() {
    return {
      menu: [],
      active: "首页",
      logo: "",
      showSelct: false,
      showMenu: true,
      languages: [
        {
          name: '中文',
          code: 'zh'
        },
        {
          name: 'Español',
          code: 'es'
        }
      ]
    };
  },
  created() {
    if (document.body.clientWidth < 700) {
      this.showSelct = true;
      this.showMenu = false
    }
    this.getMenu();
    this.getImg();
  },
  methods: {
    getImg() {
      getShop().then((res) => {
        this.logo = res.data.data.logo;
      });
    },
    getMenu() {
      getMenuList().then((res) => {
        this.menu = res.data.data;
        console.log(this.menu);
      });
    },
    menuClick(name, id) {
      this.active = name;
      this.$router.push({
        path: "/home",
        query: {
          id: id,
        },
      });
    },
    handleCommand(e) {
      console.log(e)
      this.$router.push({
        path: "/home",
        query: {
          id: e,
        },
      });
    },
    selectLanguage(value) {
      this.SET_LANG(value)
    },
    getLanguageName() {
      if (this.language == 'es') {
        return 'Español'
      } else if (this.language == 'en') {
        return 'English'
      } else {
        return '中文'
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.header {
  border-bottom: 1px solid rgb(66, 63, 63);
  width: 100%;
}

.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;

  @media (max-width: 769px) {
    padding: 0 1rem;
  }

  .logo_content {
    width: 200px;
    height: 100%;
    // font-size: 20px;
    // font-family: Vidaloka, Arial, Helvetica, sans-serif;
    // font-weight: 400;
    cursor: pointer;

    .logo {
      width: 200px;
      height: 100%;
    }
  }

  .nav_bottom {
    display: flex;
  }

  .top_btn {
    min-width: 70px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    margin-left: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active {
    border-bottom: 2px solid rgb(66, 63, 63);
  }
}</style>