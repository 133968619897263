import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 在此处配置路由
const routes = [{
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: () =>
            import ('./views/home.vue'),

    },
    {
        path: '/about',
        component: () =>
            import ('./views/about.vue'),

    },
    {
        path: '/details',
        component: () =>
            import ('./views/details.vue'),

    }
]

const router = new VueRouter({
    routes
})

export default router