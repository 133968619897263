import axios from 'axios';
import qs from 'qs';

// 创建axios实例
const service = axios.create({
    baseURL: 'https://api.juntoswin.com', // api的base_url，以实际项目为准
    timeout: 55000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
    // Do something before request is sent
    // if (store.getters.token) {
    //     config.headers['token'] = getToken() // 让每个请求携带token--['token']为自定义key 请根据实际情况自行修改
    // }

    return config
}, error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error)
});
export default service
export function get(url, params = {}) {
    let lang = sessionStorage.getItem('language') || 'zh-en'
    if(params){
        params.lang = lang
    }else{
        params = {'lang':lang}
    }
    return new Promise((resolve, reject) => {
        service
            .get(url, {
                params: params
            })
            .then(response => {
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function post(url, data = {}) {
    let lang = sessionStorage.getItem('language') || 'zh-en'
    if(data){
        data.lang = lang
    }else{
        data = {'lang':lang}
    }
    debugger
    return new Promise((resolve, reject) => {
        service.post(url, qs.stringify(data)).then(
            response => {
                // console.log(response.data.code)
                resolve(response)
            },
            err => {
                reject(err)
            }
        )
    })
}